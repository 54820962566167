// date format: Day, MM/DD/YYYY | xPM-xPM
const upcomingEventsData = [
    {
        id: 1,
        name: "Fall GBM #1",
        date: "Thurs, 10/24/2024 | 5PM-6PM",
        img: "fall2024/FallGBM_1.png"
    },

]

export default upcomingEventsData;