const execData = [
    {
      id: 3,
      name: "Zoe Chiu", 
      position: "Secretary",
      year: "2027",
      major:"Cognitive Science, Machine Learning",
      email:"zochiu@ucsd.edu",
      img: "exec_2024_2025/ZoeChiu_Normal.JPG",
      imgHover: "exec_2024_2025/ZoeChiu_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 4,
      name: "Katherine Liang", 
      position: "Treasurer",
      year: "2027",
      major:"Cognitive Science, Behavorial Neuroscience",
      email: 'k5liang@ucsd.edu',
      img: "exec_2024_2025/Katherine_Normal.JPG",
      imgHover: "exec_2024_2025/Katherine_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 5,
      name: "Lanka Diunugala", 
      position: "Community",
      year: "2025",
      major:"Cognitive Science, Design and Interaction",
      minor: "Business",
      email:"ldiunugala@ucsd.edu",
      img: "exec_2024_2025/Lanka_Normal.JPG",
      imgHover: "exec_2024_2025/Lanka_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 6,
      name: "Kolia Krajewski", 
      position: "Community",
      year: "2025",
      major:"Cognitive Science, Behavorial Neuroscience",
      email:"kkrajewski@ucsd.edu",
      img: "exec_2024_2025/KoliaKrajewski_Normal.JPG",
      imgHover: "exec_2024_2025/KoliaKrajewski_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 7,
      name: "Supriyaa Chordia", 
      position: "Community",
      major: "Cognitive Psychology",
      minor: "Data Science",
      email:"schordia@ucsd.edu",
      year: "2026",
      img: "exec_2024_2025/Supriyaa_Normal.JPG",
      imgHover: "exec_2024_2025/Supriyaa_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 8,
      name: "Mina Cicekoglu", 
      position: "Professional Relations",
      major: "Cognitive Science, Design and Interaction",
      minor: "Human Developmental Sciences",
      email: "minacicekoglu@yahoo.com",
      year: "2025",
      img: "exec_2024_2025/MinaCicekoglu_Horizontal.JPG",
      imgHover: "exec_2024_2025/MinaCicekoglu_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 9,
      name: "Maryam Vagharfard ", 
      position: "Professional Relations",
      year: "2025",
      major: "Cognitive Science, Clincal Aspects of Cognition",
      minor: "Design",
      email:"mavagharfard@ucsd.edu",
      img: "exec_2024_2025/Maryam_V_Normal.JPG",
      imgHover: "exec_2024_2025/Maryam_V_silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 10,
      name: "Melody Lin", 
      position: "Professional Relations",
      year: "2027",
      major:"Cogntive Science, Machine Learning",
      email: "yil263@ucsd.edu",
      img: "exec_2024_2025/MelodyLin_Normal.JPG",
      imgHover: "exec_2024_2025/MelodyLin_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 11,
      name: "Tommy Tran", 
      position: "Web Development",
      year: "2026",
      major: "Cognitive Science, Machine Learning and Math CS",
      email:"tot006@ucsd.edu",
      img: "exec_2024_2025/Tommy_Normal.JPG",
      imgHover: "exec_2024_2025/Tommy_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 12,
      name: "Mia Le", 
      position: "Web Development",
      year: "2025",
      major:"Cognitive Science, Design and Interaction",
      minor:"Computational Social Sciences",
      email:"mhle@ucsd.edu",
      img: "exec_2024_2025/Mia_Normal.JPG",
      imgHover: "exec_2024_2025/Mia_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 13,
      name: "Rollan Nguyen", 
      position: "Web Development",
      year: "2027",
      email: "ron004@ucsd.edu",
      major:"Cognitive Science, Machine Learning",
      img: "exec_2024_2025/rollan_normal.JPG",
      imgHover: "exec_2024_2025/rollan_silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 14,
      name: "Verina Ko", 
      position: "Design",
      year: "2027",
      major:"Cognitive Science, Design and Interaction",
      minor:"Marketing",
      email:"verinako02@gmail.com",
      img: "exec_2024_2025/VerinaKo_Normal.JPG",
      imgHover: "exec_2024_2025/VerinaKo_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 15,
      name: "Alela Rathsasombath", 
      position: "Design",
      year: "2026",
      email:"arathsasombath@ucsd.edu",
      major:"Cognitive Science, Design and Interaction",
      img: "exec_2024_2025/Alela_Normal.JPG",
      imgHover: "exec_2024_2025/Alela_silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 16,
      name: "Oswaldo Medina Jr", 
      position: "Design",
      year: "2025",
      major:"Cognitive Science, Design and Interaction",
      email: "omedinajr@ucsd.edu",
      img: "exec_2024_2025/Oswaldo_Normal.JPG",
      imgHover: "exec_2024_2025/Oswaldo_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 17,
      name: "Phoebe Ng", 
      position: "Design",
      year: "2027",
      major:"Cognitive Science, Design and Interaction",
      email:"p3ng@ucsd.edu",
      img: "exec_2024_2025/Phoebe_Normal.JPG",
      imgHover: "exec_2024_2025/Phoebe_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    //{
      //id: 18,
      //name: "Vivian Liu", 
      //position: "Design",
      //year: "2027",
     // major:"Cognitive Science, Design and Interaction",
     // email:"v9liu@ucsd.edu",
     // img: "exec_2024_2025/Phoebe_Normal.JPG",
      //imgHover: "exec_2024_2025/Phoebe_Silly.JPG",
     // url: "https://www.facebook.com/webraincogsci"
   // },
    {
      id: 19,
      name: "Varnika Seth", 
      position: "Marketing",
      year: "2027",
      major:"Cognitive Science, Behavorial Neuroscience",
      minor:"Economics",
      email:"vseth@ucsd.edu",
      img: "exec_2024_2025/Varnika_Normal.JPG",
      imgHover: "exec_2024_2025/Varnika_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 20,
      name: "Jihu Lee", 
      position: "Marketing",
      year: "2027",
      major:"Cognitive Science, Language and Culture, Linguistics",
      email:"jil337@ucsd.edu",
      img: "exec_2024_2025/Jihu_Normal.JPG",
      imgHover: "exec_2024_2025/Jihu_Silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 21,
      name: "Prashi Dali", 
      position: "Content Writing",
      year: "2026",
      major:"Cognitive Science, Design and Interaction",
      minor:"Supply Chain and Innovation",
      email:"pdali@ucsd.edu",
      img: "exec_2024_2025/Prashi_Normal.JPG",
      imgHover: "exec_2024_2025/Prashi_silly.JPG",
      url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 23,
      name: "Ishika Nahar", 
      position: "Content Writing",
      year: "2027",
      major:"Cognitive Science, Machine Learning",
      minor:"Computer Science",
      email:"inahar@ucsd.edu",
      img: "exec_2024_2025/Ishika_Normal.JPG",
      imgHover: "exec_2024_2025/Ishika_Silly.JPG",
     url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 24,
      name: "Seema Rida", 
      position: "Content Writing",
      year: "2026",
      major:"Cognitive Science, Machine Learning",
      minor:"Computer Science",
      email:"srida@ucsd.edu",
      img: "exec_2024_2025/Seema_Normal.JPG",
      imgHover: "exec_2024_2025/Seema_Silly.JPG",
     url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 25,
      name: "Keshav Tiwari", 
      position: "Media",
      year: "2026",
      major:"Cognitive Science, Machine Learning and Business Economics",
      minor:"Design",
      email:"ketiwari@ucsd.edu",
      img: "exec_2024_2025/Keshav_Normal.JPG",
      imgHover: "exec_2024_2025/Keshav_Silly.JPG",
     url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 26,
      name: "Amita Rahman", 
      position: "Media",
      year: "2027",
      major:"Cognitive Science, Cognitive Behavorial Neuroscience",
      email:"a3rahman@ucsd.edu",
      img: "exec_2024_2025/Amita_Normal.JPG",
      imgHover: "exec_2024_2025/Amita_Silly.JPG",
     url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 27,
      name: "Jiayi Li", 
      position: "Media",
      year: "2026",
      major:"Cognitive Science, Design and Interaction",
      minor: "Design, Computational Social Science",
      email:"jil318@ucsd.edu",
      img: "exec_2024_2025/Jiayi_normal.JPG",
      imgHover: "exec_2024_2025/Jiayi_Silly.JPG",
     url: "https://www.facebook.com/webraincogsci"
    },
    {
      id: 28,
      name: "Keerat Kaur", 
      position: "Media",
      year: "2026",
      major:"Cognitive Science, Machine Learning",
      email:"keeratkaurtt@gmail.com",
      img: "exec_2024_2025/Keerat_normal.JPG",
      imgHover: "exec_2024_2025/Keerat_silly.JPG",
     url: "https://www.facebook.com/webraincogsci"
    },
   
]

export default execData;