const presAdvisorsData = [ 
    {
        id: 40,
        name: "Sam Sayson", 
        position: "Presidential Advisor",
        img: "exec_2023_2024/sam.JPG",
        imgHover: "exec_2023_2024/sam_hover.JPG",
        url: "https://www.facebook.com/webraincogsci"
    },
]

export default presAdvisorsData;